app.controller('AccountDeviceController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    $log.debug('Loading AccountDeviceController');

    $scope.uploadDeviceList = function () {
        $('[name="file"]').click();
    };

    setupDeviceUploader($scope);

    $scope.registerDevice = function () {
        window.location.href = '/account/device';
    };

    $scope.subscribeDevice = function (id) {
        window.location.href = '/account/device/' + id;
    };

    $scope.editDevice = function (deviceId) {
        window.location.href = '/account/device/' + deviceId;
    };

    $scope.clearQuery = function (userId) {
        window.location.href = window.location.pathname;
    };

    function handleSubmission(method, url, fn) {
        var error = $mdDialog.alert()
            .title('This is embarrassing!')
            .htmlContent('Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support.')
            .ariaLabel('error occurred')
            .ok('OK');

        $http({
            method: method,
            url: url
        }).then(function successCallback(response) {
            if (fn && typeof fn === 'function') {
                fn.call(null);
            } else if (fn === true) {
                document.location.reload(true);
            }
        }, function errorCallback(response) {
            $mdDialog.show(error);
        });
    }

    $scope.deleteDevice = function (deviceId) {
        var confirm = $mdDialog.confirm()
            .title('Remove Device?')
            .htmlContent('This will permanently remove this device from your account. Are you sure?')
            .ariaLabel('remove device')
            .ok('Yes, remove this device')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            handleSubmission('DELETE', '/account/device/' + deviceId, true);
        });
    };

    $scope.submitRegisterDevice = function () {
        if ($scope.registerDeviceForm.$pristine || !$scope.registerDeviceForm.$valid) {
            return;
        }
        $scope.registerDeviceForm.$submitted = true;
        $('[name="registerDeviceForm"]').submit();
    };

    function stripeSubscribeResponseHandler (status, response) {
        var error = $mdDialog.alert()
            .title('Error Processing Payment')
            .htmlContent('It looks like there\'s a problem with your billing information.<br/>Please check your billing information and try again.')
            .ariaLabel('error occurred')
            .ok('OK');

        if (response.error) {
            error.htmlContent(response.error.message);
            $mdDialog.show(error);
            $scope.subscribeDeviceForm.$submitted = false;
        } else {
            $('[name="stripe-token"]').val(response.id);
            submitSubscriptionForm();
        }
    }

    function submitSubscriptionForm() {
        $('[name="agreement"]').val($scope.subscription.plan);
        if ($scope.subscribeDeviceForm.$pristine || !$scope.subscribeDeviceForm.$valid) {
            return;
        }
        $('[name="subscribeDeviceForm"]').submit();
    }



    $scope.submitSubscribeDevice = function () {
        $scope.subscribeDeviceForm.$submitted = true;
        if (!$scope.showNewBillingInformation) {
            submitSubscriptionForm();
        } else {
            Stripe.card.createToken({
                number: $scope.billing.ccnumber,
                cvc: $scope.billing.cccvc,
                exp_month: $scope.billing.ccmonth,
                exp_year: $scope.billing.ccyear,
                name: $scope.billing.name,
                address_line1: $scope.billing.address1,
                address_line2: $scope.billing.address2,
                city: $scope.billing.city,
                state: $scope.billing.state,
                address_zip: $scope.billing.postalcode,
                country: $scope.billing.country
            }, stripeSubscribeResponseHandler);
        }
    };


    /**
     * Updates the view by determining what billing collection fields should be displayed.
     */
    $scope.updateView = function () {
        $scope.showUpdateBillingQuestion = $scope.subscription.plan.indexOf('none') == -1;
        $scope.showNewBillingInformation = $scope.subscription.plan.indexOf('none') == -1 && $scope.updateBillingInformation == 'yes';
    };


    Mousetrap($('[name="deviceSearch"]')[0]).bind('enter', function () {
        $('[name="deviceSearch"]').submit();
    });
}]);
