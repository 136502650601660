app.controller('DeviceController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    var i;

    $log.debug('Loading DeviceController');

    $scope.showGPSLocation = true;
    $scope.disableCurrentLocationButton = false;

    $scope.deviceIdRegex = /^[0-9a-fA-F]{20}$/
    $scope.latLongPattern = /(\+|-|)[\d\.]+\s+(\+|-|)[\d\.]+/

    $scope.gettingGPSLocation = false;

    $scope.time = {
        hours: [],
        minutes: []
    };

    // --- Google Autocomplete ---
    var input = document.getElementById("gpsInput");

    var defaultBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(45.787636, -108.489304),
        new google.maps.LatLng(45.787636, -108.489304));

    autocomplete = new google.maps.places.Autocomplete(input, {
        bounds: defaultBounds
    });

    autocomplete.addListener('place_changed', function() {
        var place = autocomplete.getPlace();

        var coords = place.geometry.location.lat().toFixed(6) + " " + place.geometry.location.lng().toFixed(6);

        $scope.device.gps = coords;
        $scope.$apply();
    });

    var gpsInput = document.getElementById("gpsInput");
    if (gpsInput) {
        gpsInput.placeholder = "";
    }
    // --- End Autocomplete

    for (i = 0; i < 24; i++) {
        $scope.time.hours.push(s.pad(i + '', 2, '0'));
    }

    for (i = 0; i < 60; i++) {
        $scope.time.minutes.push(s.pad(i + '', 2, '0'));
    }

    $scope.beacon_time = [
        {
            value: '000',
            pretty: 'Disabled'
        }
    ];

    _.range(10, 999, 10).map(function (i) {
        $scope.beacon_time.push({
            value: s.pad(i, 3, '0'),
            pretty: i * 0.5 + ' minutes'
        });
    });

    $scope.onGPSChange = function() {
      if (!$scope.device.gps || $scope.device.gps.trim().length === 0) {
        $scope.disableCurrentLocationButton = false;
      } else {
        $scope.disableCurrentLocationButton = true;
      }
    };

    $scope.onOpStateChange = function(previousOpState) {

        if ($scope.device.op_state == 0) {
            var confirm = $mdDialog.confirm()
                .title('Kill device?')
                .htmlContent('Are you sure you want to kill the device? The device will be <br/>effectively bricked until re-enabled locally.')
                .ariaLabel('kill device')
                .ok('Yes, I\'m sure')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(null, function() {
                $scope.device.op_state = previousOpState;
            });
        }
    };

    $scope.isCabinTechDevice = function() {
        return ['A', 'B', 'C', 'D', 'E', 'F', 'G']
            .indexOf($scope.device.model_definition) !== -1;
    }

    $scope.onModelDefinitionChange = function() {
      if ($scope.device.model_definition == 2) {
        $scope.showGPSLocation = false;
        $scope.device.gps = 'n/a';
      } else {
        $scope.showGPSLocation = true;
        if ($scope.device.gps == 'n/a') {
          $scope.device.gps = '';
        }
      }

      const defaultUpdateFilenames = [
          "eaglev100.bin",
          "raptorv100.bin",
          "falconv100.bin"
      ];

      if (defaultUpdateFilenames.indexOf($scope.device.update_filename) !== -1 || !$scope.device.update_filename) {
          switch($scope.device.model_definition) {
              case "0":
                  $scope.device.update_filename = defaultUpdateFilenames[0];
                  break;
              case "1":
                  $scope.device.update_filename = defaultUpdateFilenames[1];
                  break;
              case "2":
                  $scope.device.update_filename = defaultUpdateFilenames[2];
                  break;
              case "3":
                  $scope.device.update_filename = "";
                  break;
          }
      }
    };

    $scope.setGPSToCurrentLocation = function() {
      var alert = $mdDialog.alert()
          .title('Location Error')
          .htmlContent('Sorry, we could not accurately determine your location.')
          .ok('Okay');
      $scope.gettingGPSLocation = true;
      $scope.disableCurrentLocationButton = true;

      var timer = setTimeout(function() {
        $mdDialog.show(alert).then(function () {
            $scope.gettingGPSLocation = false;
            $scope.disableCurrentLocationButton = false;
        });
      }, 20 * 1000);

      navigator.geolocation.getCurrentPosition(function(position) {
        if ($scope.gettingGPSLocation) {
          clearTimeout(timer);
          var lat = position.coords.latitude.toFixed(6),
              lng = position.coords.longitude.toFixed(6);
          $scope.device.gps = lat + ' ' + lng;
          $scope.gettingGPSLocation = false;
          $scope.deviceForm.$pristine = false;
          $scope.$apply();
        }
      });
    }

    $scope.submitDevice = function() {
        var i;

        if ($scope.deviceForm.$pristine || !$scope.deviceForm.$valid) {
            return;
        }

        if ($scope.device._self_test_time) {
          $scope.device.self_test_time = '' +
              $scope.device._self_test_time.hour +
              $scope.device._self_test_time.minute;
          $('[name="self_test_time"]').val($scope.device.self_test_time);
        }

        if ($scope.device.mgswt || $scope.device._mgswt) {
          $scope.device.mgswt = s.pad(+$scope.device._mgswt * 10, 5, '0');
          $('[name="mgswt"]').val($scope.device.mgswt);
        }

        if ($scope.device.gps_beacon_time) {
          $('[name="gps_beacon_time"]').val($scope.device.gps_beacon_time);
        }

        if ($scope.device._sensitivity) {
          $('[name="sensitivity"]').val(s.pad(+$scope.device._sensitivity, 3, '0'));
        }

        for (i = 1; i <= 15; i++) {
            var apSensitivity = $scope.device['_ap_sensitivity_' + s.pad(i, 2, '0')];
            if (apSensitivity) {
                $('[name="ap_sensitivity_' + s.pad(i, 2, '0') + '"]').val(s.pad(+($scope.device['_ap_sensitivity_' + s.pad(i, 2, '0')]), 3, '0'));
            }
        }

        if ($scope.device.alert_notification) {
          $('[name="alert_notification"]').val($scope.device.alert_notification);
        }

        $('[name="model_definition"]').val($scope.device.model_definition);
        $('[name="silenced_until"]').val($scope.device.silenced_until);
        $('[name="factory_reinitialize"]').val($scope.device.factory_reinitialize);
        $('[name="op_state"]').val($scope.device.op_state);

        if ($scope.device.gps) {
            $('[name="gps"]').val($scope.device.gps);
        }

        // $('[name="location"]').val($scope.device.location);

        for (i = 15; i < 20; i++) {
            $('[name="pstv_' + i + '"]').val(s.pad(+$scope.device['pstv_' + i], 5, '0'));
        }

        for (i = 20; i < 26; i++) {
            $('[name="mstv_' + i + '"]').val(s.pad(+$scope.device['mstv_' + i], 5, '0'));
        }

        if ($scope.device.zone_id) {
            $('[name="zone_id"]').val($scope.device.zone_id);
        }

        var j = 0;
        for (var zone_id in $scope.device.zone) {
            if ($scope.device.zone.hasOwnProperty(zone_id)) {
                if ($scope.device.zone[zone_id]) {
                    $hiddenInput = $('<input>')
                        .attr('type', 'hidden')
                        .attr('name', 'zone[' + (j++) + ']')
                        .attr('value', zone_id);
                    $('[name="deviceForm"]').append($hiddenInput);
                }
            }
        }

        $scope.deviceForm.$submitted = true;
        $('[name="deviceForm"]').submit();
    };

    $scope.cancelSubscription = function (id) {
        var confirm = $mdDialog.confirm()
            .title('Cancel Subscription?')
            .htmlContent('Immediately cancel the subscription for device <strong>' + id + '</strong>, are you sure?')
            .ariaLabel('cancel description')
            .ok('Yes, cancel subscription')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            $scope.cancelSubscriptionInProgress = true;
            window.location = '/account/cancel-subscription/' + id;
        });
    };

    $scope.selectSubscription = function(id) {
        window.location = '/account/subscribe/' + id
    };

    $scope.sendTestEvent = function () {
      var date = new Date();

      var time = date.getFullYear().toString().slice(2)
        + "/" + (date.getMonth() + 1).toString()
        + "/" + _.padStart(date.getDate().toString(), 2, '0')
        + "," + _.padStart(date.getHours().toString(), 2, '0')
        + ":" + _.padStart(date.getMinutes().toString(), 2, '0')
        + ":" + _.padStart(date.getSeconds().toString(), 2, '0');

      var gps = $scope.device.gps ? $scope.device.gps.split(' ') : [0, 0];

      var data =
        window.__DEVICE_KEY__ + "\n"
        + "TEST\n"
        + $scope.test_event + "\n"
        + "time=" + time + "\n"
        + "location=" + $scope.device.location + "\n"
        + "gps=" + gps[0] + "," + gps[1];

        console.log($scope.device);

      $scope.sendingTestEvent = true;
      var url = "/alert/" + $scope.device.id;
      $.ajax({
          type: "POST",
          url: url,
          data: data,
          dataType: 'string',
      })
        .always(function () {
          $scope.sendingTestEvent = false;
          $scope.$apply();
        });
    };

    Mousetrap($('[name="deviceSearch"]')[0]).bind('enter', function() {
        $scope.submit();
    });

    Mousetrap($('[name="deviceSearch"]')[0]).bind('enter', function() {
        $('[name="deviceSearch"]').submit();
    });
}]);
